<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body nav-filter d-flex justify-content-between row">
        <div
            class="col-md-9 col-6"
            style="border-right: 2px solid #e3e3e3;"
        >
          <el-input
              :placeholder="$t('system.add_translate_key')"
              @keyup.enter.native="addKey"
              v-model="translate"
          >
            <template slot="prepend"><b>{{ $t(`system.add_translate_key`) }}</b></template>
          </el-input>
          <div class="box-button p-0 pt-2 d-flex justify-content-between">
            <el-button
                :loading="loading.scanner_files"
                @click.prevent.stop="scannerFiles"
                type="success"
            >
              {{ $t('system.scanner_files') }}
            </el-button>
            <el-button
                :loading="loading.save"
                @click.prevent.stop="addKey"
                type="primary"
            >
              {{ $t('system.add') }}
            </el-button>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <el-input
              :placeholder="$t('system.search')"
              v-model="search"
          />
          <div class="box-button align-right p-0 pt-4">
            <el-radio
                label="key"
                v-model="searchType"
            >{{ $t('system.key') }}
            </el-radio>
            <el-radio
                label="value"
                v-model="searchType"
            >{{ $t('system.value') }}
            </el-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <el-table
            :data="searchPaginated ||paginatedModel"
            @sort-change="sortModel"
            border
            fit
            highlight-current-row
            style="width: 100%;"
            v-loading="loading.table"
        >
          <el-table-column
              :label="$t('system.key')"
              prop="key"
              sortable
          >
            <!--<template slot-scope="scope">-->
            <!--{{scope.row.key}}-->
            <!--</template>-->
          </el-table-column>
          <el-table-column
              :label="$t('system.value')"
              prop="value"
              sortable
          >
            <template slot-scope="scope">
              <el-input
                  :value="$tc(scope.row.key) ? $t(scope.row.key) : ''"
                  @keyup.ctrl.enter.native="confirmFullEdit"
                  size="small"
                  v-model="scope.row.value"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="page"
              :page-size="per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="searched ? searched.length : model.length"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
    <div class="view">
      <div class="box-button align-right d-flex">
        <el-button
            :loading="loading.save"
            @click.prevent.stop="confirmFullEdit"
            type="primary"
        >
          {{ $t('system.save') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  name: 'TranslateIndex',
  data() {
    return {
      searchType: 'key',
      model: [],
      search: '',
      translate: '',
      activeName: 'system',
      createdTimes: 0,
      page: 1,
      per_page: 10,
      loading: {
        save: false,
        table: false,
        scanner_files: false,
      },
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    searchPaginated() {
      if (this.search.length !== 0) {
        if (this.searchType === 'value') {
          return this.model.filter(data => data[this.searchType].toLowerCase().includes(this.search.toLowerCase()) || data['lastValue'].toLowerCase().includes(this.search.toLowerCase()))
              .slice(this.per_page * (this.page - 1), this.per_page * this.page);
        } else {
          return this.model.filter(data => data[this.searchType].toLowerCase().includes(this.search.toLowerCase()))
              .slice(this.per_page * (this.page - 1), this.per_page * this.page);
        }
      } else {
        return false;
      }
    },
    searched() {
      if (this.search.length !== 0) {
        if (this.searchType === 'value') {
          return this.model.filter(data => data[this.searchType].toLowerCase().includes(this.search.toLowerCase()) || data['lastValue'].toLowerCase().includes(this.search.toLowerCase()));
        } else {
          return this.model.filter(data => data[this.searchType].toLowerCase().includes(this.search.toLowerCase()));
        }
      } else {
        return false;
      }
    },
    paginatedModel() {
      return this.model.slice(this.per_page * (this.page - 1), this.per_page * this.page);
    },
  },
  watch: {
    'language'(newVal) {
      this.fetchData();
    },
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    saveTranslation(key, value) {
      let index = _.findIndex(this.model, ['key', key]);
      this.model[index].value = value;
      this.confirmFullEdit();
    },
    sortModel(val) {
      if (val.order === 'ascending') {
        this.model.sort((a, b) => (a[val.prop] > b[val.prop]) ? 1 : ((b[val.prop] > a[val.prop]) ? -1 : 0));
      } else {
        this.model.sort((a, b) => (a[val.prop] < b[val.prop]) ? 1 : ((b[val.prop] < a[val.prop]) ? -1 : 0));
      }
    },
    handleSizeChange(val) {
      this.per_page = val;
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    addKey() {
      let vm = this;
      let isKey = false;
      let new_key = this.translate;
      if (new_key.length === 0) {
        return;
      }
      var str = new_key.split('.');

      if (str.length < 2 || str[0] === '' || str[1] === '') {
        notification.notify(
            vm.$t('notify.error'),
            vm.$t('system.invalid_key'),
            'error');
        return false;
      }

      _.forEach(this.model, function (key, value) {
        if (key.key === new_key) {
          notification.notify(
              vm.$t('notify.warning'),
              vm.$t('system.key_exists'),
              'warning');
          isKey = true;
          return false;
        }
      });
      if (!isKey) {
        this.page = 1;
        this.model.unshift({
          key: new_key,
          value: '',
        });

        notification.notify(
            vm.$t('notify.info'),
            vm.$t('system.add') + ' ' + vm.$t(`system.translate_key`),
            'info');
      }
    },
    setModel(element) {
      element.value = this.$tc(element.key) ? this.$t(element.key) : '';
    },
    scannerFiles() {
      // var trans_key = require('../../../dist/i18n/trans_key.json');
      // var vm = this;
      // vm.loading.table = true;
      // vm.loading.save = true;
      // vm.loading.scanner_files = true;
      // let url = `translate/${this.$store.getters.language}/updateKey`;
      // if (this.$store.state.userModel === 'SaaSUser') {
      //   url = `saas/translate/${this.$store.getters.language}/updateKey`;
      // }
      // axios.put(`${url}`, trans_key)
      //   .then(function (response) {
      //     vm.loading.table = false;
      //     vm.loading.save = false;
      //     vm.loading.scanner_files = false;
      //     if (response.data.saved) {
      //       notification.notify(
      //         vm.$t('notify.success'),
      //         vm.$t('system.updated'),
      //         'success');
      //       vm.fetchData();
      //     } else {
      //     }
      //   })
      //   .catch(function (error) {
      //     vm.loading.table = false;
      //     vm.loading.save = false;
      //     vm.loading.scanner_files = false;
      //     notification.notify(
      //       vm.$t('notify.error'),
      //       error.response.data.error,
      //       'error');
      //   })
    },
    fetchData() {
      let vm = this;
      vm.loading.table = true;
      vm.loading.save = true;
      let url = `translate/${this.$store.getters.language}/edit`;
      if (this.$store.state.userModel === 'SaaSUser') {
        url = `saas/translate/${this.$store.getters.language}/edit`;
      }
      axios.get(url)
          .then(function (response) {
            for (let index in response.data.model) {
              let item = response.data.model[index];
              item.lastValue = item.value;
            }
            Vue.set(vm.$data, 'model', response.data.model);
            vm.loading.table = false;
            vm.loading.save = false;
          })
          .catch(function (error) {
            vm.loading.table = false;
            vm.loading.save = false;
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    cancelEdit(row) {
      row.value = row.originalValue;
      row.edit = false;
      notification.notify(
          vm.$t('notify.warning'),
          'The title has been restored to the original value',
          'warning');
    },
    confirmFullEdit() {
      let vm = this;
      vm.loading.save = true;
      vm.loading.table = true;
      let url = `translate/${this.$store.getters.language}`;
      if (this.$store.state.userModel === 'SaaSUser') {
        url = `saas/translate/${this.$store.getters.language}`;
      }
      axios.put(`${url}`, this.model)
          .then(function (response) {
            vm.loading.table = false;
            vm.loading.save = false;
            if (response.data.saved) {
              notification.notify(
                  vm.$t('notify.success'),
                  vm.$t('message.saved'),
                  'success');
              // Global.loadLocaleMessage(vm.$store.getters.language, vm.$i18n, vm.$store);
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  vm.$t('message.not_saved'),
                  'error');
            }
          })
          .catch(function (error) {
            vm.loading.table = false;
            vm.loading.save = false;
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
  },
};
</script>
